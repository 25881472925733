import { ticketsApi } from "~/api/tickets";
import {
  TicketPaymentStatus,
  type AnalyzeReceiptData,
  type CreateTicketData,
  type SendReceiptData,
  type Ticket,
  type UpdateTicketData,
} from "~/types";

export const useTicketsStructure = () => {
  const contractStore = useContractStore();

  const tickets = ref<Ticket[]>([]);
  const endorsementId = ref<number>(0);

  // Preenche o conteudo das variaveis
  function loadTickets(_endorsementId: number, _tickets: Ticket[]) {
    endorsementId.value = _endorsementId;
    tickets.value = _tickets
      .filter((t) => typeof t.value !== null && t.due_date !== null)
      .map((t) => serializeTicket(t));
  }

  // Cria um boleto para o endosso
  async function createTicket(data: CreateTicketData) {
    try {
      if (!endorsementId.value) {
        useNuxtApp().$error({
          error: new Error(),
          message: "Erro ao adicionar boleto (não existe endormentId)",
          extra: {
            endorsementId: endorsementId.value,
          },
        });
        return;
      }
      const response = await ticketsApi.createTicket(endorsementId.value, data);
      tickets.value?.push(serializeTicket(response.data));
      await contractStore.loadHiringHistory();
      useNuxtApp().$success("Boleto adicionado!");
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao adicionar boleto!",
        extra: {
          endorsementId: endorsementId.value,
        },
      });
      throw error;
    }
  }

  // Atualiza uma posição do array de boletos
  function updateTicketRecord(ticketId: number, newTicketData: Ticket) {
    const ticketIndex = tickets.value.findIndex((t) => t.id === ticketId);
    if (ticketIndex > -1) {
      tickets.value[ticketIndex] = serializeTicket(newTicketData);
    }
  }

  // Atualiza as informações de um boleto
  async function updateTicket(paymentId: number, data: UpdateTicketData) {
    try {
      const response = await ticketsApi.updateTicket(
        endorsementId.value,
        paymentId,
        data
      );
      updateTicketRecord(paymentId, response.data);
      await contractStore.loadHiringHistory();
      useNuxtApp().$success("Boleto atualizado!");
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao atualizar boleto!",
        extra: {
          endorsementId: endorsementId.value,
          paymentId,
        },
      });
      throw error;
    }
  }

  // Exclui um boleto
  async function deleteTicket(paymentId: number) {
    try {
      await ticketsApi.deleteTicket(endorsementId.value, paymentId);
      const ticketIndex = tickets.value.findIndex((t) => t.id === paymentId);
      if (ticketIndex > -1) {
        tickets.value.splice(ticketIndex, 1);
      }
      await contractStore.loadHiringHistory();
      useNuxtApp().$toast("Boleto excluído!", {
        type: "warning",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao excluir boleto!",
        extra: {
          endorsementId: endorsementId.value,
          paymentId,
        },
      });
      throw error;
    }
  }

  // Envia um recibo para um boleto
  async function sendTicketReceipt(paymentId: number, data: SendReceiptData) {
    try {
      const response = await ticketsApi.sendReceipt(
        endorsementId.value,
        paymentId,
        data
      );
      updateTicketRecord(paymentId, response.data);
      await contractStore.loadHiringHistory();
      useNuxtApp().$success("Recibo enviado!");
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao enviar recibo!",
        extra: {
          endorsementId: endorsementId.value,
          paymentId,
        },
      });
      throw error;
    }
  }

  // Envia um recibo para um boleto
  async function deleteTicketReceipt(
    paymentId: number,
    receiptFileId: string,
    options?: {
      hideSuccess?: boolean;
      noUpdateRecord?: boolean;
    }
  ) {
    try {
      const response = await ticketsApi.deleteReceiptFile(
        endorsementId.value,
        paymentId,
        receiptFileId
      );
      // A resposta do back pode ser TRUE (TODO: Remover quando o back retornar apenas o ticket atualizado)
      if (response.data === true) {
        const ticket = tickets.value.find((t) => t.id === paymentId);
        if (ticket) {
          updateTicketRecord(paymentId, {
            ...ticket,
            receipt_file: undefined,
            payment_status: TicketPaymentStatus.PENDING,
          });
        }
      }
      // Ou também o Ticket atualizado
      if (!options?.noUpdateRecord && typeof response.data === "object") {
        updateTicketRecord(paymentId, response.data);
      }
      await contractStore.loadHiringHistory();
      if (!options?.hideSuccess) {
        useNuxtApp().$toast("Recibo removido!", {
          type: "warning",
        });
      }
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao remover recibo!",
        extra: {
          endorsementId: endorsementId.value,
          paymentId,
          receiptFileId,
        },
      });
      throw error;
    }
  }

  // Analisa um recibo enviado
  async function analyzeTicketReceipt(
    paymentId: number,
    data: AnalyzeReceiptData
  ) {
    try {
      const response = await ticketsApi.analyzeReceipt(
        endorsementId.value,
        paymentId,
        data
      );
      updateTicketRecord(paymentId, response.data);
      const message =
        data.analysis_conclusion === "approved"
          ? "Recibo aprovado!"
          : "Recibo recusado!";
      await contractStore.loadHiringHistory();
      useNuxtApp().$toast(message, {
        type: data.analysis_conclusion === "approved" ? "success" : "warning",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao analisar recibo!",
        extra: {
          endorsementId: endorsementId.value,
          paymentId,
          ...data,
        },
      });
      throw error;
    }
  }

  return {
    tickets,
    loadTickets,
    createTicket,
    updateTicket,
    deleteTicket,
    sendTicketReceipt,
    deleteTicketReceipt,
    analyzeTicketReceipt,
  };
};
