import { UserRoles } from "~/types";
import { canExecuteRequest } from ".";
import type { ContractHiring, HiringHistory } from "~/types/hiring";
import type { InternalQuotation } from "~/types/quotation";

/**
 * Retorna estado atual das apólices de um contrato
 */
export const hiringApi = {
  contractHiring(
    contractId: number,
    rc: "geral" | "profissional",
    role?: UserRoles
  ) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp().$api.get<ContractHiring>(
      `/${role}/contract/${contractId}/contract_hiring/`,
      {
        params: {
          rc,
        },
      }
    );
  },
  // Cria uma cotação interna
  createQuote(
    contractId: number,
    rc: "geral" | "profissional",
    role?: UserRoles
  ) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp()
      .$api.post<InternalQuotation>(
        `/${role}/contract/${contractId}/contract_quote/create/`,
        undefined,
        {
          params: {
            rc,
          },
        }
      )
      .then((response) => response.data);
  },
  getHiringHistory(contractId: number | string, role?: UserRoles) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp()
      .$api.get<HiringHistory[]>(
        `/${role}/contract/${contractId}/hiring_history`
      )
      .then((r) => r.data)
      .catch((r) => []);
  },
};
