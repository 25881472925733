import { type InternalProposal, type Endorsement } from "@/types";
import type { InternalQuotation } from "~/types/quotation";

export const hiringUtils = {
  // Precisa de uma nova cotação?
  needANewQuote({
    contract_quote,
    endorsement,
    proposal,
  }: {
    contract_quote?: InternalQuotation | null;
    proposal?: InternalProposal | null;
    endorsement?: Endorsement | null;
  }) {
    // Se não existir endosso ou cotação
    if (!contract_quote && !endorsement) {
      return true;
    }
    return false;
  },
};
