import type { Endorsement } from ".";

export enum InternalProposalStatus {
  APPROVED = "approved",
  REFUSED = "refused",
}

export interface InternalProposalFile {
  filename: string;
  created_at: string;
  proposal_file_id: string;
}

export interface InternalProposal {
  id: number;
  contract_quote_id: number;
  to_send: boolean;
  sent: boolean;
  start_date: string;
  end_date: string;
  proposal_status: InternalProposalStatus;
  proposal_status_description: string;
  endorsement_number: null | number | string;
  created_at: string;
  updated_at: string;
  proposal_files?: [InternalProposalFile];
  endorsement: null | Endorsement;
}

export interface InternalProposalListItem {
  id: number;
  value: number;
  premium_value: number;
  iof_value: number;
  contract: string;
  company_cnpj: string;
  endorsement_number?: string | null;
  document_number: string | number;
  status: InternalProposalStatus;
  start_date: string;
  end_date: string;
  created_at: string;
  actions?: string;
  proposal_files?: [InternalProposalFile];
}

export interface UpdateInternalProposal {
  premium_value: number | string;
  iof_value: number | string;
  value: number | string;
  start_date: string;
  end_date: string;
}
