import {
  addMinutes,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parse,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import type { Option } from "~/components/atoms/select/index.vue";

export const formatDate = (
  date: Date | string,
  formatString?: string,
  options?: {
    noFixTimezone?: boolean;
  }
) => {
  /**
   * Solução para o problema do timezone estar tirando
   * um dia da data por conta do horário ser 00
   */
  if (!options?.noFixTimezone) {
    if (typeof date === "string") {
      date = new Date(date);
      date = addMinutes(date, date.getTimezoneOffset());
    } else {
      date = addMinutes(date, date.getTimezoneOffset());
    }
  }

  return format(date, formatString || "dd/MM/yyyy", {
    locale: ptBR,
  });
};

export const parseDate = (date: string, format?: string) => {
  return parse(date, format || "yyyy-MM-dd", new Date(), {
    locale: ptBR,
  });
};

export function applyDateInputMask(newValue: string, oldValue: string) {
  const newValueNumbers = newValue.replace(/[\D]/g, "");

  if (newValue.length === 2 && oldValue.length === 1) {
    return `${newValueNumbers}/`;
  }
  if (newValue.length === 5 && oldValue.length === 4) {
    return `${newValueNumbers.slice(0, 2)}/${newValueNumbers.slice(2, 4)}/`;
  }
  if (newValue.length >= 6) {
    return `${newValue.slice(0, 10)}`;
  }
  return newValue;
}

export function validateDate(dateValue: Date | string, parseFormat?: string) {
  try {
    let value = dateValue;
    if (typeof dateValue === "string") {
      value = parseDate(dateValue, parseFormat || "dd/MM/yyyy");
    }
    if (isValid(value)) {
      if (isBefore(value, new Date("1980-01-01"))) return false;
      return true;
    }
    return false;
  } catch (er) {
    return false;
  }
}

export function isTodayOrAfter(date: Date, dateToCompare?: Date) {
  // Se não passou data de comparação, vai ser hoje
  if (dateToCompare === undefined) dateToCompare = new Date();
  if (isSameDay(date, dateToCompare)) {
    return true;
  }
  if (isAfter(date, dateToCompare)) {
    return true;
  }
  return false;
}

export const makeMonthsOptions = (reverse?: boolean): Option[] => {
  const options = Array.from({ length: 12 }).map((_, index) => {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    return {
      code: String(index + 1),
      label: months[index],
    } as Option;
  });
  return reverse ? options.reverse() : options;
};

export const makeYearsOptions = (untilThatYear: number): Option[] => {
  const yearOptions: Option[] = [];
  const atualYear = new Date().getFullYear();

  for (let year = clone<number>(atualYear); year >= untilThatYear; year--) {
    yearOptions.push({
      code: String(year),
      label: String(year),
    });
  }
  return yearOptions;
};
