import type { AxiosError } from "axios";

export const makeErrorMessage = (message?: string) => {
  if (!message) return;
  return message.endsWith(".") ? message : `${message}.`;
};

export interface SiriusError {
  success: false;
  message: string;
  error_code: string;
}

/**
 * Tratamento de erro básico (mostra para o usuário)
 * - retorna booleano indicando se existe um erro previsto
 */
export function canShowErrorForUser(_error: any) {
  const error = _error as AxiosError<SiriusError>;

  if (error?.response?.data?.message) {
    let errorMessage = error.response?.data?.message;
    // Adiciona "!" no final
    const lastChar = errorMessage.charAt(errorMessage.length - 1);
    if (lastChar !== "!" && lastChar !== ".") {
      errorMessage = `${errorMessage}!`;
    }
    useNuxtApp().$toast(errorMessage, {
      type: "error",
    });
    return true;
  }
  return false;
}

/**
 * Mostra os erros em tela pro usuário
 */
export function toastErrors(
  error: SiriusError,
  messagesPerError: Record<string, string>
) {
  const toast = useNuxtApp().$toast;

  if (error?.success === false) {
    const { error_code = "" } = error;

    if (messagesPerError[error_code]) {
      toast(messagesPerError[error_code], {
        type: "error",
      });
    }
  }
}

export const FIELD_ERROR_CLASS = "field-with-error";

export function scrollToErrors() {
  const firstErrorElement = document.querySelector(`.${FIELD_ERROR_CLASS}`);
  if (firstErrorElement) {
    firstErrorElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}
